import React from "react"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import "../../styles/global.css"

const theme = {}
const GlobalStyle = createGlobalStyle`
 *{
    box-sizing: border-box;
    margin:0;
    padding: 0;
    font-family: 'Kanit', sans-serif;
     
  }
`

function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <main>{children}</main>
    </ThemeProvider>
  )
}

export default Layout
