import styled from "styled-components"
import { Link } from "gatsby"
import { FaTimes } from "react-icons/fa"

interface SideBtn {
  cartOpen: boolean
}

export const SidebarContainer = styled.aside<SideBtn>`
  position: fixed;
  z-index: 999;
  width: 350px;
  height: 100%;
  background: #ffc500;
  display: grid;
  align-items: center;

  top: 0;
  transition: 0.3s ease-in-out;
  right: ${({ cartOpen }) => (cartOpen ? "0" : "-1000px")};

  @media screen and (max-width: 400px) {
    width: 100%;
  }
`

export const CloseIcon = styled(FaTimes)`
  color: #e31837;
`

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  border: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`

export const CartTitle = styled.h2`
  margin: 1rem;
  margin-top: 2rem;
  font-size: 2rem;
  text-align: center;
`

export const CartWrap = styled.div`
  max-height: 70%;
  display: flex;
  overflow: auto;
`
export const TotalWrap = styled.div`
  text-align: center;
  color: #000;
  margin-bottom: 10px;
`
export const EmptyCart = styled.p`
  text-align: center;
  color: #fff;
`

export const SideBtnWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
`

export const SidebarRoute = styled(Link)`
  background: #e31837;
  white-space: nowrap;
  padding: 16px 64px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`
