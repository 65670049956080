import styled from "styled-components"
import { Link } from "gatsby"

export const FooterContainer = styled.footer`
  background-color: #0d0909;
`

export const FooterWrap = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
`

export const SocialMedia = styled.section`
  max-width: 1300px;
  width: 100%;
`

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  max-width: 1100px;

  margin: 16px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    align-items: center;
  }
`

export const SocialLogo = styled(Link)`
  color: #fff;

  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  @media screen and (max-width: 400px) {
    font-size: 1rem;
  }
`

export const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  width: 240px;
  @media screen and (max-width: 820px) {
    justify-content: center;
  }
`

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
  margin-right: 10px;
`

export const Info = styled.p`
  font-size: 1rem;
  color: #fff;
  @media screen and (max-width: 820px) {
    font-size: 0.7rem;
  }
`
export const WebLink = styled.a`
  color: #fff;
`
