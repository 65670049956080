import React from "react"
import { FaFacebook, FaInstagram } from "react-icons/fa"
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcons,
  SocialIconLink,
  Info,
  WebLink,
} from "./FooterElements"

const Footer = () => {
  const year = new Date().toLocaleDateString("en-us", {
    year: "numeric",
  })
  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/"> CHICKEN CRISPERS © {year}</SocialLogo>
            <SocialIcons>
              <SocialIconLink
                href="https://www.facebook.com/ChickenCrispers"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.instagram.com/chicken_crispers_uae/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <FaInstagram />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
        <Info>
          DEVELOPED BY{" "}
          <WebLink
            href="https://www.linkedin.com/in/sami-ilahi/"
            target="_blank"
            rel="noopener noreferrer"
          >
            SAMI ILAHI
          </WebLink>
        </Info>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
