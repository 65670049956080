import product1 from "../../images/product-1.jpg"
import product2 from "../../images/product-2.jpg"
import product3 from "../../images/product-3.jpg"
import sweet1 from "../../images/sweet3.jpg"
import sweet2 from "../../images/sweet-2.jpg"
import sweet3 from "../../images/sweet-3.jpg"

export const productData = [
  {
    id: 1,
    img: product1,
    alt: "Burger",
    name: "Swiss Burger",
    desc: "Beef patty, Crispers sauce, Emmental Cheese, Pickles, Dimi, glad Mushroom ",
    price: "23.00 ",
    button: "Add to Cart",
  },
  {
    id: 2,
    img: product2,
    alt: "Chicken",
    name: "Strips 5/5",
    desc: " 5 Pieces of Strips, Fries, Coleslaw, Bread, Sauce of choice, Soft Drink",
    price: "24.00 ",
    button: "Add to Cart",
  },
  {
    id: 3,
    img: product3,
    alt: "Chicken",
    name: "Chicken Zinger",
    desc: " Chicken, Dijon Mayonnaise, American Cheese, Tomato, Prickles, lettuce, Smoked Turkey ",
    price: "22.00 ",
    button: "Add to Cart",
  },
]

export const productDataTwo = [
  {
    id: 4,
    img: sweet2,
    alt: "Donuts",
    name: "Doughlicious",
    desc: "Belgian chocolate glazed donuts covered in icing with sprinkles on top",
    price: "9.99 ",
    button: "Add to Cart",
  },
  {
    id: 5,
    img: sweet3,
    alt: "Ice Cream",
    name: "Caramel Wonder",
    desc: "Vanilla ice cream covered with caramel and chocolate glaze topped with a coco stick",
    price: "12.99 ",
    button: "Add to Cart",
  },
  {
    id: 6,
    img: sweet1,
    alt: "Brownie",
    name: "Brownie Bunch",
    desc: "Double fudge brownie squares topped with white chocolate pieces and macadamia nuts",
    price: "9.99 ",
    button: "Add to Cart",
  },
]
