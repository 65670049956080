import React from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  CartWrap,
  TotalWrap,
  CartTitle,
  SidebarRoute,
  EmptyCart,
  SideBtnWrap,
} from "./CartElements"
import ProductCart from "../ProductCart/index"
import { useAppSelector } from "../../features/hooks"

const Cart = ({ cartOpen, toggleHandler }) => {
  const ProductsInCart = useAppSelector(state => state.cart.products)
  const ProductTotalPrice = useAppSelector(state => state.cart.total)

  return (
    <SidebarContainer cartOpen={cartOpen}>
      <Icon onClick={toggleHandler}>
        <CloseIcon />
      </Icon>
      {ProductsInCart.length > 0 ? (
        <CartTitle>Your choices are perfect </CartTitle>
      ) : (
        <CartTitle>Your Cart is empty!</CartTitle>
      )}

      <CartWrap>
        <Table>
          <TableBody>
            {ProductsInCart.map(product => (
              <ProductCart key={product.id} product={product} />
            ))}
          </TableBody>
        </Table>
      </CartWrap>
      {ProductsInCart.length > 0 ? (
        <TotalWrap>
          <h4>Total</h4>
          {ProductTotalPrice.toFixed(2)} Dhs
        </TotalWrap>
      ) : (
        <EmptyCart>Please check our menu for tasty dishes</EmptyCart>
      )}

      <SideBtnWrap>
        {ProductsInCart.length > 0 ? (
          <SidebarRoute to="/menu">Order Now</SidebarRoute>
        ) : (
          <SidebarRoute to="/menu">Check Menu</SidebarRoute>
        )}
      </SideBtnWrap>
    </SidebarContainer>
  )
}

export default Cart
