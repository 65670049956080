import React from "react"

import TableRow from "@material-ui/core/TableRow"

import TableCell from "@material-ui/core/TableCell"
// import DeleteIcon from "@mui/icons/Delete"
import { ProductButton } from "./ProductCartElements"
import { useAppDispatch, useAppSelector } from "../../features/hooks"
import { removeFromCart } from "../../features/cart-slice"

function ProductCart({ product }) {
  const productId = product.id

  const ProductQuantity = useAppSelector(state => state.cart.quantity)
  const dispatch = useAppDispatch()

  const removeFromCartHandler = () => {
    dispatch(removeFromCart(product))
  }
  return (
    <TableRow>
      <TableCell>
        <img src={product.img} alt={product.alt} width={100} />
        <h4>{product.name}</h4>
      </TableCell>
      {/* <TableCell>{product.name}</TableCell> */}
      <TableCell style={{ textAlign: "center" }}>
        <h5> Price / unit: </h5> {product.price} Dhs <br /> <h5>Quantity: </h5>
        {ProductQuantity[productId]}
        <br /> <h5>Price: </h5>
        {ProductQuantity[productId] * Number(product.price)} Dhs
      </TableCell>
      <TableCell>
        <ProductButton onClick={removeFromCartHandler}>Delete</ProductButton>
      </TableCell>
    </TableRow>
  )
}

export default ProductCart
