import styled from "styled-components"

import { FaHamburger, FaShoppingBag } from "react-icons/fa"

export const Nav = styled.nav`
  background: transparent;
  display: flex;
  align-items: center;
`

export const Logo = styled.img`
  width: 200px;

  @media screen and (max-width: 400px) {
    width: 100px;
  }
`

export const NavIcon = styled.div`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #f39200;

  p {
    transform: translate(-175%, 100%);
    font-weight: bold;
  }
`
export const NavHome = styled(NavIcon)`
  right: 120px;
`
export const Bars = styled(FaHamburger)`
  font-size: 2rem;
  transform: translate(-50%, -15%);
`
export const NavShop = styled(NavIcon)`
  right: 120px;
  top: 22px;
`
export const ShopIcon = styled(FaShoppingBag)`
  font-size: 2rem;
  transform: translate(-50%, -15%);
`
export const Counter = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f39200;
  color: #fff;
  top: -55px;
  left: 10px;
`
