import React from "react"
import {
  ProductsContainer,
  ProductWrapper,
  ProductsHeading,
} from "./ProductsElements"
import Product from "../Product"

const Products = ({ heading, data }) => {
  return (
    <ProductsContainer>
      <ProductsHeading>{heading}</ProductsHeading>
      <ProductWrapper>
        {data.map(product => {
          return <Product product={product} key={product.id} />
        })}
      </ProductWrapper>
    </ProductsContainer>
  )
}

export default Products
