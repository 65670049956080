import React from "react"
import { Link } from "gatsby"
import {
  Nav,
  NavIcon,
  Bars,
  Logo,
  NavHome,
  ShopIcon,
  NavShop,
  Counter,
} from "./NavbarElements"
import logo from "../../images/logo-chicken.png"
import { useAppSelector } from "../../features/hooks"

const Navbar = ({ toggle, toggleHandler }) => {
  const ProductsInCart = useAppSelector(state => state.cart.products)
  return (
    <>
      <Nav>
        <Logo src={logo} alt="logo" />
        <Link to="/">
          <NavHome>
            <p>Home</p>
          </NavHome>
        </Link>

        <NavShop>
          <ShopIcon onClick={toggleHandler} />
          <Counter>
            <span>{ProductsInCart.length}</span>
          </Counter>
        </NavShop>

        <NavIcon onClick={toggle}>
          <p>Menu</p>
          <Bars />
        </NavIcon>
      </Nav>
    </>
  )
}

export default Navbar
