import React from "react"
import {
  ProductTitle,
  ProductCard,
  ProductImg,
  ProductInfo,
  ProductDesc,
  ProductPrice,
  ProductButton,
} from "./ProductElements"
import { addProduct } from "../../features/cart-slice"
import { useAppDispatch, useAppSelector } from "../../features/hooks"

function Product({ product }) {
  const dispatch = useAppDispatch()

  const productAddHandler = () => {
    dispatch(addProduct(product))
  }

  return (
    <ProductCard key={product.id}>
      <ProductImg src={product.img} alt={product.alt} />
      <ProductInfo>
        <ProductTitle>{product.name}</ProductTitle>
        <ProductDesc>{product.desc}</ProductDesc>
        <ProductPrice>{product.price} Dhs</ProductPrice>
        <ProductButton onClick={productAddHandler}>
          {product.button}
        </ProductButton>
      </ProductInfo>
    </ProductCard>
  )
}

export default Product
